import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Home from "../components/sections/home"
import Features from "../components/sections/features"
import Footer from "../components/sections/footer"
import GetStarted from "../components/sections/getstarted"
import About from "../components/sections/about"
import Contact from "../components/sections/contact"
import Portfolio from "../components/sections/portfolio"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Navigation />
    <Home />
    {/* <Portfolio /> */}
    <About />
    <Contact />
    <Footer />
  </Layout>
)

export default IndexPage
