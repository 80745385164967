import React from "react"
import styled from "styled-components"

import Map from '../common/map'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Section } from "../global"
import { StyledContainer, Subtitle, SectionTitle, FeaturesGrid } from "../common/styled/styled"

const Contact = () => {

	const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "grafito" }, name: { eq: "instagram" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

	return (
		<StyledSection id="contact">
			<StyledContainer>
				<Subtitle>Live in Luxury</Subtitle>
				<SectionTitle>Contact</SectionTitle>
				<FeaturesGrid>
					<FeatureItem>
						<Map style={{maxWidth:'100%'}} 
							width={'100%'} 
							height={'300'}></Map>
					</FeatureItem>
					<FeatureItem>
						<FeatureTitle>Connect with us</FeatureTitle>
						<FeatureText>
							Phone: <PhoneLink number={`+17865055170`}>(786)505-5170</PhoneLink>
							<br/>
							<br/>
							Email: <EmailLink email={`info@grafito.studio`}>info@grafito.studio</EmailLink>
						</FeatureText>
						<FeatureText>

							<ContactAncher target={'_blank'}
								href={'https://www.instagram.com/studiografito/'}>
							<ImageWrapper>
								<StyledImage fluid={data.file.childImageSharp.fluid} />
								<br />
							</ImageWrapper>
							</ContactAncher>
						</FeatureText>
					</FeatureItem>
				</FeaturesGrid>
			</StyledContainer>
		</StyledSection>
	)
}

const FeatureItem = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const PhoneLink = ({ number, children }) => (
	<ContactAncher target={'_blank'} href={`tel:${number}`}>{children}</ContactAncher>
)
const EmailLink = ({ email, children }) => (
	<ContactAncher target={'_blank'} href={`mailto:${email}`}>{children}</ContactAncher>
)

const FeatureText = styled.p`
  text-align: left;
`

const ContactAncher = styled.a`
	color: ${props => props.theme.color.black.regular};
`



export default Contact;



const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`
const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 30px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 30px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 30px;
  }
`