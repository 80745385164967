import React, { useEffect, useState } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Scrollspy from "react-scrollspy"
import { Menu, X } from "react-feather"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Container } from "../../global"
import {
	Nav,
	NavItem,
	Brand,
	StyledContainer,
	NavListWrapper,
	MobileMenu,
	Mobile,
	ActionsContainer,
} from "./style"

const NAV_ITEMS = [
	"About", 
	"Contact", 
	// "Portfolio", 
	""
]


const Navigation = (props) => {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [hasScrolled, setHasScrolled] = useState(false);

	const data = useStaticQuery(graphql`
	  query {
		file(sourceInstanceName: { eq: "grafito" }, name: { eq: "logo" }) {
		  childImageSharp {
			fluid(maxWidth: 1000) {
			  ...GatsbyImageSharpFluid_tracedSVG
			}
		  }
		}
	  }
	`)

	useEffect(() => {
		const handleScroll = () => {

			if (window.pageYOffset > 32) {
				setHasScrolled(true);
			} else {
				setHasScrolled(false);
			}
		}

		window.addEventListener("scroll", handleScroll)

		return () => {
			window.removeEventListener("scroll", handleScroll)
		};
	}, []);
	const closeMobileMenu = () => {
		if (mobileMenuOpen) {
			setMobileMenuOpen(false);
		}
	}

	const toggleMobileMenu = () => {
		setMobileMenuOpen(!mobileMenuOpen);
	}


	const getNavAnchorLink = item => (
		<AnchorLink href={`#${item.toLowerCase()}`} onClick={closeMobileMenu}>
			{item}
		</AnchorLink>
	)

	const getNavList = ({ mobile = false }) => (
		<NavListWrapper mobile={mobile}>
			<Scrollspy
				items={NAV_ITEMS.map(item => item.toLowerCase())}
				currentClassName="active"
				mobile={mobile}
				offset={-64}
			>
				{NAV_ITEMS.map(navItem => (
					<NavItem key={navItem}>{getNavAnchorLink(navItem)}</NavItem>
				))}
			</Scrollspy>
		</NavListWrapper>
	)

	return (
		<Nav {...props} scrolled={hasScrolled}>
			<StyledContainer>
				<Brand>
					<Scrollspy offset={-64} item={["home"]} currentClassName="active">
						<AnchorLink href="#home" onClick={closeMobileMenu}>

							<ImageWrapper>
								<StyledImage fluid={data.file.childImageSharp.fluid} />
								<br />
							</ImageWrapper>
						</AnchorLink>
					</Scrollspy>
				</Brand>
				<Mobile>
					<button
						onClick={toggleMobileMenu}
						style={{ color: "black", background: "none" }}
					>
						{mobileMenuOpen ? (
							<X size={24} alt="close menu" />
						) : (
							<Menu size={24} alt="open menu" />
						)}
					</button>
				</Mobile>

				<Mobile hide>{getNavList({})}</Mobile>
				{/* <ActionsContainer>
					<button>Sign up</button>
				</ActionsContainer> */}
			</StyledContainer>
			<Mobile>
				{mobileMenuOpen && (
					<MobileMenu>
						<Container>{getNavList({ mobile: true })}</Container>
					</MobileMenu>
				)}
			</Mobile>
		</Nav>
	)
}

export default Navigation;

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
  max-height: 60px;
`

const StyledImage = styled(Img)`
  width: 60px;
`
