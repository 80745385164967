import React from "react"
import styled from "styled-components"

import { graphql, useStaticQuery } from "gatsby"

import { Section } from "../global"
import Img from "gatsby-image"

import { StyledContainer, Subtitle, SectionTitle, FeatureText } from "../common/styled/styled"

const About = () => {



	const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "grafito" }, name: { eq: "portrait" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

	return (
		<Section id="about">
			<StyledContainer>
				<Subtitle>Studio Director</Subtitle>
				<SectionTitle>Isabel Barbosa</SectionTitle>
				<AboutGrid>
					<AboutItem>
						<FeatureText>
							The most beautiful cosita in the world. The most accomplished architect. AIA and leed on lock down,
							So you have nothing to worry about anything. Design services. Technical drawings. Owners rep. she's got it all
							You just name it. 
					</FeatureText>
					</AboutItem>
					<AboutImage>

						<ImageWrapper>
							<StyledImage fluid={data.file.childImageSharp.fluid} />
							<br />
						</ImageWrapper>
					</AboutImage>
				</AboutGrid>
			</StyledContainer>
		</Section>
	)
}

const StyledImage = styled(Img)`
  width: 300px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 200px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 200px;
  }
`
const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`
const AboutGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`

const AboutItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const AboutImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`



export default About;