
import React from "react"

const Map = ({width = 600, height = 450, place = 'ChIJEcHIDqKw2YgRZU-t3XHylv8', key = 'AIzaSyCIXVY3lkyCza4VY-sBo3nb-_Fgfz2yqo4'}) => (
	<iframe 
		width={width} 
		height={height} 
		style={{border:0}} 
		loading="lazy" 
		allowfullscreen 
		src={`https://www.google.com/maps/embed/v1/place?q=place_id:${place}&key=${key}`}
		>
	</iframe>
);

export default Map;